import { HStack, IconButton, Text, VStack } from "@chakra-ui/react";
import Image from "next/image";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { useRef } from "react";
import { poppins } from "@/styles/font";
import { useQuery, useQueryClient } from "react-query";
import { ICategory } from "@/lib/interfaces/ICategory";
import { AxiosError } from "axios";
import { fetchCategory } from "@/api";
import Loader from "../loader/Loader";
import { useRouter } from "next/router";
import { CategoryItemType } from "@/types/CategoryItemType";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";

const CategoryItem = ({ catId, image, title }: CategoryItemType) => {
  const router = useRouter();
  return (
    <VStack
      alignItems={"center"}
      justifyContent={"center"}
      p={6}
      my={5}
      rounded={"md"}
      boxShadow={"0px 2px 4px rgba(0, 0, 0, 0.15)"}
      width={"150%"}
      minHeight={"120px"}
      maxHeight={"120px"}
      _hover={{ cursor: "pointer" }}
      onClick={() =>
        router.push({
          pathname: "/catalog/[categoryId]",
          query: { categoryId: catId, category: title.toLowerCase().replaceAll(" ", "-") },
        })
      }
    >
      <Image src={image} alt={title} width={30} height={30} />
      <Text
        textAlign={"center"}
        className={poppins.className}
        fontSize={"12px"}
        color={"martlinegray.900"}
      >
        {title}
      </Text>
    </VStack>
  );
};

const CategoryLayout = () => {
  const swiperRef = useRef<SwiperRef>(null);
  const queryClient = useQueryClient();

  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data: allCategory,
  } = useQuery<ICategory[], AxiosError>("all-categories", fetchCategory, {
    initialData: queryClient.getQueryData("all-categories"),
    useErrorBoundary: true,
  });

  return (
    <HStack
      backgroundColor={"white"}
      boxShadow={"0px 2px 4px rgba(0, 0, 0, 0.15)"}
      marginY={8}
      paddingX={"4px"}
      rounded={"md"}
      spacing={4}
      display={{ base: 'none', lg: 'flex' }}
    >
      {isLoading && <Loader />}
      {isSuccess && (
        <>
          <IconButton
            color={"white"}
            rounded={"full"}
            backgroundColor={"martlinegray.600"}
            aria-label={"Swipe left"}
            slot={"container-start"}
            ml={3.5}
            icon={<CaretLeft size={20} />}
            _hover={{
              backgroundColor: "martlinegray.400",
              color: "martlineorange.500",
            }}
            onClick={() => swiperRef.current?.swiper.slidePrev()}
          />
          <Swiper
            ref={swiperRef}
            onSwiper={(swiper) => {
              swiperRef.current!.swiper = swiper;
            }}
            slidesPerView={9}
            spaceBetween={50}
            centeredSlides={true}
            centeredSlidesBounds={true}
            modules={[Pagination]}
            style={{ padding: "0 4px" }}
          >
            {allCategory?.map((item) => (
              <SwiperSlide key={item.title}>
                <CategoryItem
                  key={item._id}
                  catId={item._id}
                  image={item.icon}
                  title={item.title}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <IconButton
            color={"white"}
            rounded={"full"}
            backgroundColor={"martlinegray.600"}
            aria-label={"Swipe right"}
            ml={3.5}
            icon={<CaretRight size={20} />}
            _hover={{
              backgroundColor: "martlinegray.400",
              color: "martlineorange.500",
            }}
            onClick={() => swiperRef.current?.swiper.slideNext()}
          />
        </>
      )}
    </HStack>
  );
};

export default CategoryLayout;
